import React from "react";
import "../styles/New.css";
import { Container, Grid } from "@mui/material";
import Newcard from "./Newcard";
import logo1 from "../images/cnt1.jpg";
import logo2 from "../images/cnt3.jpg";
import logo3 from "../images/lcl.jpg";
import logo4 from "../images/drm8.jpg";
import logo5 from "../images/cntc.jpg";
import logo6 from "../images/drm7.jpg";
import logo7 from "../images/drm5.jpg";
import logo8 from "../images/drm1.jpg";

const New = () => {
  const cardData = [
    {
      imageUrl: logo1,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo2,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo3,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo4,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo5,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo6,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo7,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo8,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo1,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo2,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo3,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo4,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo5,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo6,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo7,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo8,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },

    {
      imageUrl: logo4,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo5,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo6,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo7,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo8,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo1,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo2,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo3,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo4,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo1,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo2,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
    {
      imageUrl: logo3,
      heading: "HAMPTON LTD",
      timeline: "36 Deakin Street North, Hampton, VIC 3188",
    },
  ];

  return (
    <div>
      <Container className="new">
        <div className="newdevh">
          <h1>New Developments</h1>
        </div>

        <Grid container spacing={3}>
          {cardData.map((item, index) => (
            <Grid key={index} item xs={12} md={3}>
              <Newcard
                imageUrl={item.imageUrl}
                heading={item.heading}
                timeline={item.timeline}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default New;
