import React from "react";
import { Container, Grid } from "@mui/material";
import Latestcard from "./Latestcard";
import "../styles/Latest.css";
import logo1 from "../images/s-1.jpg";
import logo2 from "../images/s-2.jpg";
import logo3 from "../images/s-3.jpg";
import logo4 from "../images/s-4.jpg";
import logo5 from "../images/s-5.jpg";
import logo6 from "../images/s-6.jpg";

const newsData = [
  {
    imageUrl: logo1,
    heading: "NOVEMBER INTEREST RATE HIKE ALL BUT GUARANTEED",
  },
  {
    imageUrl: logo2,
    heading:
      "STEREOSONIC CO-FOUNDER LISTS RIVERFRONT MANSION IN TIGHTLY-HELD TOORAK POCKET",
  },
  {
    imageUrl: logo3,
    heading:
      "CHEAPEST CAPITAL CITY SUBURBS WHERE BUYERS CAN STILL SNAG A BARGAIN",
  },
  {
    imageUrl: logo4,
    heading:
      "THE SURPRISING SEASIDE TOWNS AND SUBURBS WHERE PROPERTY VALUES HAVE GROWN MOST THIS YEAR",
  },
  {
    imageUrl: logo5,
    heading: "PROPERTY PRICES TIPPED TO RISE 13% BY END OF NEXT YEAR",
  },
  {
    imageUrl: logo6,
    heading: "WHAT IS BUILD-TO-RENT? CAN IT HELP WITH THE RENTAL CRISIS?",
  },
];

const Latest = () => {
  return (
    <Container sx={{ textAlign: "center", py: 4 }} className="latest">
      <h2>Latest property news</h2>

      <Grid container spacing={4}>
        {newsData.map((item, index) => (
          <Grid key={index} item xs={12} md={4}>
            <Latestcard
              imageUrl={item.imageUrl}
              heading={item.heading}
              timeline="21 days ago"
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Latest;
