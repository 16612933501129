import React from "react";
import { Container, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "../styles/footer.css";
import Footertab from "./Footertab";
import { Link } from "react-router-dom";
import Country from "./Country";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <List id="social-icons">
              <ListItem>
                <ListItemIcon>
                  <FacebookOutlinedIcon className="smicons" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TwitterIcon className="smicons" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LinkedInIcon className="smicons" />
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <YouTubeIcon className="smicons" />
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={9}>
            <List id="social-links">
              <ListItem>
                <Link to="/">Advertise with Us</Link>
              </ListItem>
              <ListItem>
                <Link to="/contact">Contact Us</Link>
              </ListItem>

              <ListItem>
                <a href="#">About Us</a>
              </ListItem>

              <ListItem>
                <a href="#"> Legal</a>
              </ListItem>

              <ListItem>
                <a href="#">Report Bugs</a>
              </ListItem>
              <ListItem>
                <a href="#"> Site Map</a>
              </ListItem>

              <ListItem>
                <a href="#">FAQ</a>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <div className="Specific_Services">
        <Container>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="spcserv">Specific Services</h2>
            </Link>
            <Country />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Customer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Buyer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Seller</a>
                </ListItem>

                <ListItem>
                  <a href="#">Buyer's Agent</a>
                </ListItem>
                <ListItem>
                  <a href="#">Seller's Agent</a>
                </ListItem>
                <ListItem>
                  <a href="#">Real Estate company</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="tradesmenServices">
        <Container>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="trds">Tradesmen Services</h2>
            </Link>
            <Country />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Builder</a>
                </ListItem>
                <ListItem>
                  <a href="#">BrickLayer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Electricians</a>
                </ListItem>
                <ListItem>
                  <a href="#">Landscaper</a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3}>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Plumbers</a>
                </ListItem>
                <ListItem>
                  <a href="#">Stonemason</a>
                </ListItem>
                <ListItem>
                  <a href="#">Carpenter</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Painter</a>
                </ListItem>

                <ListItem>
                  <a href="#">Welder</a>
                </ListItem>
                <ListItem>
                  <a href="#">Tiler</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Plasterer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Glazier</a>
                </ListItem>
                <ListItem>
                  <a href="#">Handyman</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="professional">
        <Container>
          <div className="specser">
            <Link to="/" className="lnks">
              <h2 id="prps">Professional Services</h2>
            </Link>
            <Country />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <List id="proflinks">
                <ListItem>
                  <a href="#">Financial Institution</a>
                </ListItem>
                <ListItem>
                  <a href="#">Financial Advisor</a>
                </ListItem>

                <ListItem>
                  <a href="#">Mortgage Broker</a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3}>
              <List id="proflinks1">
                <ListItem>
                  <a href="#">Lawyer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Accountant</a>
                </ListItem>
                <ListItem>
                  <a href="#">Book Keeper</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Building Engineer</a>
                </ListItem>

                <ListItem>
                  <a href="#">Building Designer</a>
                </ListItem>
                <ListItem>
                  <a href="#">Architects</a>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={3}>
              <List id="proflinks2">
                <ListItem>
                  <a href="#">Pest Control Inspector</a>
                </ListItem>

                <ListItem>
                  <a href="#">Soil Technician</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="footab">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Footertab />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="copyrgt">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>© 2023 All Rights Reserved</p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
