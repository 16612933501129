import React, { useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  Grid,
} from "@mui/material";
import "../styles/Property.css";
import Propertycard from "./Propertycard";
import logo1 from "../images/lct.png";
import logo2 from "../images/sve.png";
import logo3 from "../images/lctt.png";

const Property = () => {
  const [selectedValue, setSelectedValue] = useState("Buying"); // Initialize with 'Buying'

  const handleLabelClick = (value) => {
    setSelectedValue(value);
  };
  return (
    <div>
      <Container className="property">
        <h2>Property Knowledge</h2>
      </Container>
      <Container className="lidiv">
        <FormControl className="lifrom">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            class="liradio"
          >
            <FormControlLabel
              value="Buying"
              className={`Buying ${
                selectedValue === "Buying" ? "selected" : ""
              }`}
              control={<Radio style={{ display: "none" }} />}
              label="Buying"
              onClick={() => handleLabelClick("Buying")}
            />
            <FormControlLabel
              value="Renting"
              className={`Buying ${
                selectedValue === "Renting" ? "selected" : ""
              }`}
              control={<Radio style={{ display: "none" }} />}
              label="Renting"
              onClick={() => handleLabelClick("Renting")}
            />
            <FormControlLabel
              value="Selling"
              className={`Buying ${
                selectedValue === "Selling" ? "selected" : ""
              }`}
              control={<Radio style={{ display: "none" }} />}
              label="Selling"
              onClick={() => handleLabelClick("Selling")}
            />
            <FormControlLabel
              value="Researching"
              className={`Buying ${
                selectedValue === "Researching" ? "selected" : ""
              }`}
              control={<Radio style={{ display: "none" }} />}
              label="Researching"
              onClick={() => handleLabelClick("Researching")}
            />
          </RadioGroup>
        </FormControl>
      </Container>
      <Container>
        <Grid container spacing={4}>
          {/* First input field */}
          <Grid item xs={12} sm={6} md={4}>
            <Propertycard
              imageUrl={logo1}
              heading="GET ESTIMATED PROPERTY PRICES WITH A REALESTIMATE"
              description="See how much your property's worth whether you own it or want to buy it"
              linkText="Check property values"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Propertycard
              imageUrl={logo2}
              heading="NEED HELP WITH A MORTGAGE?"
              description="Compare your finance options to make an informed call."
              linkText="Explore home loans"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Propertycard
              imageUrl={logo3}
              heading="EXPLORE SUBURB PROFILES"
              description="Check out different suburb profiles and find one that's right for you."
              linkText="Research suburbs"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Property;
