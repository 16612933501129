import React from 'react';
import {Container,Grid,Button} from '@mui/material';

import '../styles/Findmore.css';

const Findmore = ()=>{

    return(

        <div>
       <Container className='findmore'>
       <Button variant="outlined" id='findbtn'>Find More</Button>
       </Container>
        </div>
    );

}


export default Findmore;