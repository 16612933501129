import React, { useState } from "react";
import axios from "axios";
import "../styles/Verification.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const Verification = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleVerify = () => {
    // Your verification logic here
  };

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3 style={{ marginBottom: "50px" }}>Enter Verification Code</h3>
              <ToastContainer />
              <div className="otp-field" style={{ textAlign: "center" }}>
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />

                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />

                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />

                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />

                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />

                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value=""
                  name="firstdigit"
                />
              </div>
              <Button
                id="sbbtnv"
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Verification;
