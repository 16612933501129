import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import Country from "./Country";

const Footertab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabss">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ "& .MuiTab-root": { textAlign: "left" } }}
          id="footb"
        >
          <Tab label="Real estate" />
          <Tab label="New homes" />
          <Tab label="Popular areas" />
          <Tab label="Popular searches" />
        </Tabs>
      </Box>
      <div role="tabpanel" hidden={value !== 0}>
        <div className="rsa">
          <Container id="realesta">
            <Link to="/" className="lnks">
              <h2>Real estate in Australia</h2>
            </Link>
            <Country />
            <Grid container spacing={2} className="tab-scrl">
              <Grid item xs={4} className="grd-wdth">
                <List id="proflinks">
                  <ListItem>
                    <a href="#">Real estate NSW</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate TAS</a>
                  </ListItem>

                  <ListItem>
                    <a href="#"> Real estate WA</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate TAS</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate VIC</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate SA</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate QLD</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4} className="grd-wdth">
                <List id="proflinks1">
                  <ListItem>
                    <a href="#">Real estate NT</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate ACT</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={4} className="grd-wdth">
                <List id="proflinks2">
                  <ListItem>
                    <a href="#">Real estate Adelaide</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Canberra</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Darwin</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Brisbane</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Hobart</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Sydney</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Melbourn</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Real estate Perth</a>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div role="tabpanel" hidden={value !== 1}>
        <div className="nhm">
          <Container>
            <Link to="/" className="lnks">
              <h2>Build new homes in Australia</h2>
            </Link>
            <Country />
            <Grid container spacing={2}>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks">
                  <ListItem>
                    <a href="#">Home builders</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">House and land packages Sydney</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Home builders Sydney</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Home builders Melbourne</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Hobart house prices</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks1">
                  <ListItem>
                    <a href="#">House and land</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">House and land packages Canberra</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">House and land packages Melbourne</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Home builders Brisbane</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks2">
                  <ListItem>
                    <a href="#">Home builders Perth</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">House designs</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">House and land packages Brisbane</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">House and land packages Perth</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks3">
                  <ListItem>
                    <a href="#">Home builders Perth</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Land estates</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">New apartments</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">House and land packages Adelaide</a>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div role="tabpanel" hidden={value !== 2}>
        <div className="poparea">
          <Container>
            <Link to="/" className="lnks">
              <h2>Browse popular areas in Australia</h2>
            </Link>
            <Country />
            <Grid container spacing={2}>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks">
                  <ListItem>
                    <a href="#">Sydney house prices</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Adelaide house prices</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks1">
                  <ListItem>
                    <a href="#">Brisbane house prices</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Suburb profiles</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks2">
                  <ListItem>
                    <a href="#">Canberra house prices</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Melbourne house prices</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks3">
                  <ListItem>
                    <a href="#">Perth house prices</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Hobart house prices</a>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div role="tabpanel" hidden={value !== 3}>
        <div className="pps">
          <Container>
            <Link to="/" className="lnks">
              <h2>Browse popular property searches</h2>
            </Link>
            <Country />
            <Grid container spacing={2}>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks">
                  <ListItem>
                    <a href="#">Commercial property news</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">How much does it cost to build a house</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Property guides</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Renovation</a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks1">
                  <ListItem>
                    <a href="#">Capital gains tax Australia</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Property news</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Rent to buy</a>
                  </ListItem>

                  <ListItem>
                    <a href="#"> How much can I borrow</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks2">
                  <ListItem>
                    <a href="#">Negative gearing</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Mortgage calculator</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Auction results</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">The block</a>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={3} className="grd-wdth">
                <List id="proflinks3">
                  <ListItem>
                    <a href="#">How much is my house worth</a>
                  </ListItem>
                  <ListItem>
                    <a href="#">Selling property</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Stamp duty calculator</a>
                  </ListItem>

                  <ListItem>
                    <a href="#">Australia housing market</a>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Footertab;
