import React from "react";
import "../styles/Latestcard.css";

const Latestcard = ({ imageUrl, heading, timeline }) => {
  return (
    <div>
      <div className="lat">
        <div className="box">
          <div className="limg">
            <img src={imageUrl} alt="latest" />
          </div>
          <div className="detail-lbox">
            <h6>{heading}</h6>
            <p>{timeline}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Latestcard;
