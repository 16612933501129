import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import "../styles/Signin.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { useState, useEffect } from "react";

const Signin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // emai:'',
    // password:'',
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter a email",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "You must enter a password",
    },
  });

  const handleSubmit = () => {
    const formFields = Object.keys(formData);
    let newFormValues = { ...formData };
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;
      if (currentValue === "") {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
      }
    }

    setFormData(newFormValues);
    const { email, password } = newFormValues;
    const finalObj = { email: email.value, password: password.value };
    //console.log(finalObj);

    axios
      .post("http://localhost:13000/signin", finalObj, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Signin successful! Welcome back.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/");
          }, 5000);
        }
      })
      .catch((err) => {
        if (err.response) {
          const status = err.response.status;

          if (status === 401) {
            toast.error("Invalid email or password. Please try again.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            // Log other errors to the console
            console.log(err);
          }
        }
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setFormData({
    //   ...formData,
    //   [event.target.name]: event.target.value,
    // });

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
        error: false,
      },
    });
  };

  useEffect(() => {
    if (!formData.email.value.trim()) {
      formData.email.errorMessage = "Email is required";
      formData.email.error = true;
    } else if (!/\S+@\S+\.\S+/.test(formData.email.value)) {
      formData.email.errorMessage = "Invalid email address";
      formData.email.error = true;
    }
  }, [formData]);

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3>Sign in</h3>
              <p>Sign in with this account across the following sites.</p>
              <ToastContainer />
              <TextField
                margin="normal"
                fullWidth
                id="email"
                value={formData.email.value}
                error={formData.email.error}
                helperText={formData.email.error && formData.email.errorMessage}
                onChange={handleInputChange}
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                fullWidth
                value={formData.password.value}
                error={formData.password.error}
                helperText={
                  formData.password.error && formData.password.errorMessage
                }
                onChange={handleInputChange}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                id="sbbtn"
                type="submit"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
              >
                Sign in
              </Button>
              <Link to="/" className="frgtlink">
                Forgot your password?
              </Link>

              <div className="or_flx">
                <span className="lft_rw"></span>
                <span className="or">OR</span>
                <span className="right_rw"></span>
              </div>

              <div className="scl_btn">
                <button>
                  <svg
                    viewBox="0 0 256 262"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      fill="#EA4335"
                    ></path>
                  </svg>
                  Continue with Google
                </button>
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1025 1025"
                    width="24"
                    height="24"
                    version="1.1"
                  >
                    <g id="surface1">
                      <path
                        fillRule="nonzero"
                        fill="#ffffff"
                        d="M 1024 512 C 1024 229.230469 794.769531 0 512 0 C 229.230469 0 0 229.230469 0 512 C 0 767.554688 187.230469 979.371094 432 1017.78125 L 432 660 L 302 660 L 302 512 L 432 512 L 432 399.199219 C 432 270.878906 508.4375 200 625.390625 200 C 681.40625 200 740 210 740 210 L 740 336 L 675.4375 336 C 611.835938 336 592 375.46875 592 415.957031 L 592 512 L 734 512 L 711.300781 660 L 592 660 L 592 1017.78125 C 836.769531 979.371094 1024 767.554688 1024 512 "
                      ></path>
                    </g>
                  </svg>
                  Continue with Facebook
                </button>
                <button className="cntn_apple">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 170 170"
                    width="24"
                    height="24"
                    version="1.1"
                  >
                    <g fillRule="evenodd">
                      <path
                        fill="#ffffff"
                        d="m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z"
                      ></path>
                    </g>
                  </svg>
                  Continue with Apple
                </button>
              </div>
              <p className="nt_sgndup">
                Not signed up?
                <Link to="/signup" className="lnk">
                  {" "}
                  Create an account.
                </Link>
              </p>
              <div className="sgnup_ftr">
                <p>Personal Information Collection Statement.</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Signin;
