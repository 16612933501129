import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../images/logo.png";
import "../styles/Header.css";
import CustomDrawer from "./CustomDrawer";
import Currencydropdown from "./Currencydropdown";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppBar, Toolbar, Typography, Container, Grid } from "@mui/material";

const Header = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <AppBar position="static" className="AppBar">
      <Container>
        <ToastContainer />
        <Toolbar className="Toolbar" id="main_head">
          {/* Logo Section */}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item className="logo-wdth">
              <Typography variant="h6" className="Logo">
                <Link to="/" className="lnks">
                  <img src={logo} alt="Logo" />
                </Link>
              </Typography>
            </Grid>
            <Grid item id="crncy">
              <Grid container spacing={2} className="MenuItems">
                <Grid item>
                  <Currencydropdown />
                </Grid>
              </Grid>
            </Grid>
            {/* Menu Section */}
            <Grid item id="lgn-sgnm">
              <Grid container spacing={2} className="MenuItems" id="lgn-sgn">
                <Grid item>
                  <Typography variant="h6" className="MenuItem">
                    <Link to="/signin" className="lnks">
                      Sign In
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className="MenuItem">
                    <Link to="/signup" className="lnks">
                      Sign Up
                    </Link>
                  </Typography>
                </Grid>
                <Grid item className="dewr">
                  <Typography variant="h6" className="MenuItem">
                    <CustomDrawer />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
