import React from "react";
import "../styles/Propertycard.css";

const Propertycard = ({ imageUrl, heading, description, linkText }) => {
  return (
    <div className="property-card">
      <div className="img-box">
        <div className="prpty_prc">
          <img src={imageUrl} alt="Property" />
        </div>
        <h6>{heading}</h6>
        <p>{description}</p>
        <a href="#">{linkText}</a>
      </div>
    </div>
  );
};

export default Propertycard;
