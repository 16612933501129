import React from "react";
import { Container, Grid } from "@mui/material";
import Dreamcard from "./Dreamcard";
import "../styles/Dreamhome.css";
import logo1 from "../images/lcl.jpg";
import logo2 from "../images/cnt.jpg";
import logo3 from "../images/cnt1.jpg";
import logo4 from "../images/cnt2.jpg";
import image1 from "../images/bed.png";
import image2 from "../images/bath.png";
import image3 from "../images/car.png";

const Dreamhome = () => {
  return (
    <div>
      <Container className="dreamhome">
        <h1>Dream Homes</h1>
      </Container>

      <Container>
        <Grid container spacing={3}>
          {/* First input field */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="GREENWICH"
              desc="Stone Real Estate Greenwich"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="PEARL BEACH"
              desc="Coast Realty Terrigal"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BRONTE"
              desc="Highland Double Bay"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo1}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="HUNTERS HILL"
              desc="TRG"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo2}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MIDDLETON GRANGE"
              desc="Richard Matthews Real Estate"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo3}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="MOSS VALE"
              desc="Ashbourne"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dreamcard
              imageUrl={logo4}
              image1={image1}
              image2={image2}
              image3={image3}
              heading="BONDI BEACH"
              desc="CBRE Residential Projects"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dreamhome;
