import React from "react";
import banner from "../videos/banner.mp4";
import "../styles/Banner.css";

const Banner = () => {
  return (
    <div className="banner-container" style={{ background: "#000" }}>
      <video
        className="banner-video"
        autoPlay
        muted
        loop
        style={{ width: "100%", height: "790px", objectFit: "cover" }}
      >
        <source src={banner} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className="afterBanner"
        style={{ width: "100%", height: "78px", background: "#000" }}
      ></div>
    </div>
  );
};

export default Banner;
