import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/International.css";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../images/fltr.png";

const International = () => {
  const [selectedOption, setSelectedOption] = useState("Buy"); // Initial selected option

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const [formData, setFormData] = useState({
    categories: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform actions with the form data here
    console.log("Form submitted:", formData);
  };

  return (
    <div>
      <Container className="inter">
        <h1>International Real Estates</h1>
        <p>The Home of Online Marketing for Real Estate Properties Worldwide</p>
      </Container>
      <Container className="internat">
        <Slider {...settings}>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbs"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbs">
              Buy
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbss"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbss">
              Rent
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsa"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsa">
              Sold
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsb"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsb">
              Address
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsc"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsc">
              Agents
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsn"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsn">
              Sell
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsm"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsm">
              Foreclosures
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsv"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsv">
              Auctions
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsx"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsx">
              Open House Inspections
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsz"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsz">
              Display Homes
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsd"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsd">
              B&B [Bed & Breakfast]
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsdm"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsdm">
              Commercial Properties
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsdmn"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsdmn">
              Professional Services
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="buy"
              id="li_tbsdm"
              name="id_lbl"
              style={{ display: "none" }}
              checked={selectedOption === "Buy"}
              onChange={handleOptionChange}
            />
            <label className="buyg" htmlFor="li_tbsdm">
              International Countries
            </label>
          </div>
        </Slider>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* First input field */}
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <TextField
                name="categories"
                value={formData.categories}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                placeholder="Search Your Categories"
                id="cat"
              />
            </Grid>
            {/* Button 1 */}
            <Grid item xs={6} sm={2} md={2} lg={2}>
              <Button variant="outlined" fullWidth id="filter">
                <img src={logo} alt="Logo" id="fliterimg" />
                FILTERS
              </Button>
            </Grid>

            {/* Button 2 */}
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <Button variant="contained" color="primary" fullWidth id="search">
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default International;
