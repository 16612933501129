import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import "../styles/Signin.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { useState, useEffect } from "react";

const Signin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // emai:'',
    // password:'',
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter a email",
    },
  });

  const handleSubmit = () => {
    const formFields = Object.keys(formData);
    let newFormValues = { ...formData };
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formData[currentField].value;
      if (currentValue === "") {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
      }
    }

    setFormData(newFormValues);
    const { email } = newFormValues;
    const finalObj = { email: email.value };
    //console.log(finalObj);

    axios
      .post("http://localhost:13000/adminSignin", finalObj)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Signin successful! Welcome back.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/verification");
          }, 5000);
        }
      })
      .catch((err) => {
        if (err.response) {
          const status = err.response.status;

          if (status === 401) {
            toast.error("Invalid email or password. Please try again.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            // Log other errors to the console
            console.log(err);
          }
        }
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setFormData({
    //   ...formData,
    //   [event.target.name]: event.target.value,
    // });

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
        error: false,
      },
    });
  };

  useEffect(() => {
    if (!formData.email.value.trim()) {
      formData.email.errorMessage = "Email is required";
      formData.email.error = true;
    } else if (!/\S+@\S+\.\S+/.test(formData.email.value)) {
      formData.email.errorMessage = "Invalid email address";
      formData.email.error = true;
    }
  }, [formData]);

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3>Admin Login</h3>
              <ToastContainer />
              <TextField
                margin="normal"
                fullWidth
                id="email"
                value={formData.email.value}
                error={formData.email.error}
                helperText={formData.email.error && formData.email.errorMessage}
                onChange={handleInputChange}
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                id="sbbtn"
                type="submit"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
              >
                NEXT
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Signin;
