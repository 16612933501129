import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Explore from "../components/Explore";
import Sponsor from "../components/Sponsor";
import Insight from "../components/Insight";
import Findmore from "../components/Findmore";
import logo from "../images/home_banner.jpg";
import logo1 from "../images/new-apartments.jpg";
import logo2 from "../images/new-land-estates.jpg";
import logo3 from "../images/new-house-and-land.jpg";
import logo4 from "../images/home-builders.jpg";
import logo5 from "../images/home-designs.jpg";
import logo6 from "../images/bs.jpg";
import logo7 from "../images/drm.jpg";
import logo8 from "../images/cnt2.jpg";
import logo9 from "../images/cnt1.jpg";
import logo10 from "../images/s-1.jpg";
import logo11 from "../images/s-2.jpg";
import logo12 from "../images/s-3.jpg";
import logo13 from "../images/s-4.jpg";
import logo14 from "../images/s-5.jpg";
import logo15 from "../images/s-6.jpg";
import logo16 from "../images/drm6.jpg";
import logo17 from "../images/drm5.jpg";
import logo18 from "../images/drm4.jpg";
import logo19 from "../images/drm3.jpg";
import logo20 from "../images/drm2.jpg";
import logo21 from "../images/drm1.jpg";

import { Container, Grid } from "@mui/material";
import "../styles/Newhome.css";
import { useState } from "react";

const Newhome = () => {
  const [selectedOption, setSelectedOption] = useState("EXPLORE");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Header />

      <Container id="banner">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="vdo_bnr_mg">
              <img src={logo} alt="Logo" className="responsive-image" />
              <div className="overlayy"></div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="detail-box">
              <h1>New homes start here.</h1>
              <p>
                Explore new apartments, land estates, house and land, home
                designs, builders and more.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="detail-box">
              <ul>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbs"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "EXPLORE"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbs">
                    EXPLORE
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbss"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "NEW APARTMENTS"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbss">
                    NEW APARTMENTS
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbsa"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "LAND"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbsa">
                    LAND
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbsb"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "HOUSE & LAND"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbsb">
                    HOUSE & LAND
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbsc"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "BUILDERS"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbsc">
                    BUILDERS
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="buy"
                    id="li_tbsn"
                    name="id_lbl"
                    style={{ display: "none" }}
                    checked={selectedOption === "HOME DESIGNS"}
                    onChange={handleOptionChange}
                  />
                  <label className="buyggg" htmlFor="li_tbsn">
                    HOME DESIGNS
                  </label>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container id="explore">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="exp">
              <h2>Explore all new homes in Australia</h2>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={1}></Grid>

                <Grid item xs={12} sm={2}>
                  <Explore imageUrl={logo1} heading="NEW APARTMENTS" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Explore imageUrl={logo2} heading="LAND ESTATES" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Explore imageUrl={logo3} heading="HOUSE & LAND" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Explore imageUrl={logo4} heading="BUILDERS" />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Explore imageUrl={logo5} heading="HOME DESIGNS" />
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container id="spnsr" style={{ marginTop: "55px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="snhm">
              <h2 style={{ marginBottom: "35px" }}>Sponsored new homes</h2>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Sponsor
                    imageUrl={logo6}
                    heading="NSW"
                    desc1="Invest in your ultimate future coastal lifestyle at Port Macquarie"
                    desc2="Limited Port Macquarie land from $375,000*"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Sponsor
                    imageUrl={logo7}
                    heading="NSW"
                    desc1="Invest in your ultimate future coastal lifestyle at Port Macquarie"
                    desc2="Limited Port Macquarie land from $375,000*"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Sponsor
                    imageUrl={logo8}
                    heading="NSW"
                    desc1="Invest in your ultimate future coastal lifestyle at Port Macquarie"
                    desc2="Limited Port Macquarie land from $375,000*"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Sponsor
                    imageUrl={logo9}
                    heading="NSW"
                    desc1="Invest in your ultimate future coastal lifestyle at Port Macquarie"
                    desc2="Limited Port Macquarie land from $375,000*"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container id="insight" style={{ marginTop: "55px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="fninh">
              <h2 style={{ marginBottom: "35px" }}>
                Featured News & Insights for New Homes
              </h2>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo10}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo11}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo12}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo13}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo14}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo15}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo16}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo17}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo18}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo19}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo20}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Insight
                    imageUrl={logo21}
                    heading="4 features of a luxury Brisbane penthouse"
                    desc="Sponsored by Kokoda Property"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Findmore />
      <Footer />
    </>
  );
};

export default Newhome;
