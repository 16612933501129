import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import International from "../components/International";
import Property from "../components/Property";
import Latest from "../components/Latest";
import Findmore from "../components/Findmore";
import Homelisting from "../components/Homelisting";
import Dreamhome from "../components/Dreamhome";
import New from "../components/New";
import Footer from "../components/Footer";
import "../styles/index.css";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <International />
      <Property />
      <Findmore />
      <Latest />
      <Findmore />
      <Homelisting />
      <Findmore />
      <Dreamhome />
      <Findmore />
      <New />
      <Findmore />
      <Footer />
    </>
  );
};

export default Home;
