import React, { useContext } from "react";
import { Stepper, StepLabel, Step } from "@mui/material";
import UserSignup from "../components/UserSignup";
import Questions from "../components/Questions";
import CustomQuestions from "../components/CustomQuestions";
import { multiStepContext } from "../StepContext";
import Verification from "../components/Verification";
const Signup = () => {
  const { currentStep, finalData } = useContext(multiStepContext);
  function showStep(step) {
    switch (step) {
      case 1:
        return <UserSignup />;
      case 2:
        return <Questions />;
      case 3:
        return <CustomQuestions />;
      case 4:
        return <Verification />;
      default:
        return <UserSignup />;
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="center-stepper">
          <Stepper
            style={{ display: "none" }}
            activeStep={currentStep - 1}
            orientation="horizontal"
          >
            <Step>
              <StepLabel>First Step</StepLabel>
            </Step>

            <Step>
              <StepLabel> Second Step</StepLabel>
            </Step>
            <Step>
              <StepLabel> third Step</StepLabel>
            </Step>
            <Step>
              <StepLabel> fourth Step</StepLabel>
            </Step>
          </Stepper>
        </div>
        {showStep(currentStep)}
      </header>
    </div>
  );
};

export default Signup;
