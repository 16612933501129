import React from 'react';

const Dreamcard = (props) => {
    const { imageUrl, image1, image2, image3, heading, desc } = props;
  
    return (
      <div>
        <div className="res_sldr1">
          <img src={imageUrl} alt="" />
          <div className="tke_out">
            <h6 className="hsb">
              {heading}
              <span>.NSW</span>
            </h6>
            <div className="flx_svg">
              <div className="hmestt">
                <img src={image1} alt="" />
                <span>6</span>
              </div>
              <div className="hmestt">
                <img src={image2} alt="" />
                <span>3</span>
              </div>
              <div className="hmestt">
                <img src={image3} alt="" />
                <span>2</span>
              </div>
            </div>
            <p>{desc}</p>
          </div>
        </div>
      </div>
    );
  };

 export default Dreamcard;