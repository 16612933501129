import React from "react";
import "../styles/Contact.css";

const Contactcard = ({ svgPath, heading, desc }) => {
  return (
    <>
      <div className="cnt-container">
        <div className="boxx" id="explorex">
          <div
            className="img-box1x"
            dangerouslySetInnerHTML={{ __html: svgPath }}
          />
          <div className="detail-box1" style={{ marginTop: "10px" }}>
            <h6
              style={{
                fontWeight: "700",
                fontSize: "21px",
                marginTop: "0px",
              }}
            >
              {heading}
            </h6>
            <p>{desc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactcard;
