import React from 'react';
import '../styles/Homelistcard.css';


const Homelistcard = ({imageUrl,heading,description,image1,image2,image3}) => {


    return(
        <div>
        <div className="responsive_flx" id="intrntnl_dsply">
	     <div className="res_sldr">
			<div className="list-img">
		  <img src={imageUrl}  alt="" />
		  </div>
			<div className="tke_out">	
				<h6>{heading}</h6>
				<p>{description}</p>
				<div className="flx_svg">
					<div className="hmest">
                    <img src={image1}  alt="" />
						<span>3</span>
					</div>
					<div className="hmest">
                    <img src={image2}  alt="" />
						<span>4</span>
					</div>
					<div className="hmest">
                    <img src={image3}  alt="" />
						<span>2</span>
					</div>	
					<div className="hmest">
						<span><span className="dt_spn">.</span> House</span>
					</div>
				</div>	
			</div>	
		</div>
        </div>
        </div>
    );

}


export default Homelistcard;