import React from "react";
import "../styles/Newhome.css";

const Sponsor = ({ imageUrl, heading, desc1, desc2 }) => {
  return (
    <>
      <div class="sldr_spnsrd_flx">
        <div class="spnsrd_hms">
          <img src={imageUrl} alt="" />
          <div class="spnsrd_add" style={{ textAlign: "left" }}>
            <p>{heading}</p>
            <h4>{desc1}</h4>
            <p>{desc2}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsor;
