// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import Newhome from "./Pages/Newhome";
import Contact from "./Pages/Contact";
import Admin from "./Pages/Admin";
import Verification from "./Pages/Verification";
import { StepProvider } from "./StepContext"; // Import StepProvider here

function App() {
  return (
    <Router>
      <StepProvider>
        {/* Wrap your routes with StepProvider */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/newhome" element={<Newhome />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/verification" element={<Verification />} />
        </Routes>
      </StepProvider>
    </Router>
  );
}

export default App;
