import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contactcard from "../components/Contactcard";
import logo from "../images/cntc.jpg";
import { Container, Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import "../styles/Contact.css";

const Contact = () => {
  const svgPaths = [
    `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="lt-custom-block__icon lt-flex-shrink-0 replaced-svg" aria-hidden="true" focusable="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.96675L23 7.76685L12 13.5416L1 7.76685L12 1.96675Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M5.125 10.1835V13.5416L12 17.1666L19.5 13.5416V9.7002" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10.75 8.70825L7.625 10.7618L7.625 18.3751" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.625 22.0001C8.66053 22.0001 9.5 21.1886 9.5 20.1875C9.5 19.1865 8.66053 18.375 7.625 18.375C6.58947 18.375 5.75 19.1865 5.75 20.1875C5.75 21.1886 6.58947 22.0001 7.625 22.0001Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
    `,
    `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="lt-custom-block__icon lt-flex-shrink-0 replaced-svg" aria-hidden="true" focusable="false">
    <path d="M4.10333 7.28564C4.75958 7.28564 5.08771 7.80945 5.41583 8.33326C5.74396 8.85707 6.07208 9.38088 6.72833 9.38088C7.38458 9.38088 7.71271 8.85707 8.04083 8.33326C8.36896 7.80945 8.69708 7.28564 9.35333 7.28564C10.0096 7.28564 10.3377 7.80945 10.6658 8.33326C10.994 8.85707 11.3221 9.38088 11.9783 9.38088C12.6346 9.38088 12.9627 8.85707 13.2908 8.33326C13.619 7.80945 13.9471 7.28564 14.6033 7.28564C15.2596 7.28564 15.5877 7.80945 15.9158 8.33326C16.244 8.85707 16.5721 9.38088 17.2283 9.38088C17.8846 9.38088 18.2127 8.85707 18.5408 8.33326C18.869 7.80945 19.1971 7.28564 19.8533 7.28564C20.1688 7.28564 20.4085 7.4067 20.6087 7.59061V21C20.6087 22.1046 19.7133 23 18.6087 23H5.39136C4.28679 23 3.39136 22.1046 3.39136 21V7.4702C3.62333 7.3557 3.86333 7.28564 4.10333 7.28564Z" stroke="#333F48"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23V14H18V23" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.38736 1L1.47827 5H22.4783L20.5692 1H3.38736Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M5.30432 14.6191H11.0435V18.8096H5.30432V14.6191Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M16.3043 17.2383V18.2859" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M22.5 5H1.5V9.34709C1.67576 9.07747 2.88952 7.28564 4.10327 7.28564C4.75952 7.28564 5.08765 7.80945 5.41577 8.33326C5.7439 8.85707 6.07202 9.38088 6.72827 9.38088C7.38452 9.38088 7.71265 8.85707 8.04077 8.33326C8.3689 7.80945 8.69702 7.28564 9.35327 7.28564C10.0095 7.28564 10.3376 7.80945 10.6658 8.33326C10.9939 8.85707 11.322 9.38088 11.9783 9.38088C12.6345 9.38088 12.9626 8.85707 13.2908 8.33326C13.6189 7.80945 13.947 7.28564 14.6033 7.28564C15.2595 7.28564 15.5876 7.80945 15.9158 8.33326C16.2439 8.85707 16.572 9.38088 17.2283 9.38088C17.8845 9.38088 18.2126 8.85707 18.5408 8.33326C18.8689 7.80945 19.197 7.28564 19.8533 7.28564C20.5095 7.28564 20.8376 7.80945 21.1658 8.33326C21.4939 8.85707 21.822 9.38088 22.4783 9.38088L22.5 5Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
    `,
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" height="140" width="140" className="lt-custom-block__icon lt-flex-shrink-0 replaced-svg" aria-hidden="true" focusable="false"><g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)"><path d="M3.5,13.5v9a1,1,0,0,0,1,1H9a.5.5,0,0,0,.5-.5V19a2.5,2.5,0,0,1,5,0v4a.5.5,0,0,0,.5.5h4.5a1,1,0,0,0,1-1V14" fill="none" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path><path d="M0.5 13L12 1.5 23.5 13" fill="none" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="lt-custom-block__icon lt-flex-shrink-0 replaced-svg" aria-hidden="true" focusable="false">
     <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M14.4063 7.13993C14.1645 6.82509 13.864 6.56398 13.5256 6.36858C12.8606 5.9847 12.0493 5.85447 11.2491 6.06888C9.6487 6.49771 8.69895 8.14272 9.12778 9.74311C9.34219 10.5433 9.86065 11.1808 10.5256 11.5647C10.864 11.7601 11.2404 11.8898 11.6339 11.9418" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M11.7871 11.9792C12.1806 12.0312 12.557 12.1609 12.8955 12.3563C13.5604 12.7402 14.0788 13.3777 14.2932 14.1779C14.7221 15.7783 13.7723 17.4233 12.1719 17.8521C11.3717 18.0666 10.5604 17.9363 9.89547 17.5524C9.55703 17.357 9.25653 17.0959 9.01476 16.7811" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M12 4.5V5.5" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M12 18V19.5" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     </svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24" fill="none" className="lt-custom-block__icon lt-flex-shrink-0 replaced-svg" aria-hidden="true" focusable="false">
     <path fill-rule="evenodd" clip-rule="evenodd" d="M15 1C16.1046 1 17 1.89543 17 3L17 21C17 22.1046 16.1046 23 15 23L3 23C1.89543 23 1 22.1046 1 21L1 9L7 1L15 1Z" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M7 1L7 9H1" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M4 13H14" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M4 16H14" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     <path d="M4 19H14" stroke="#333F48" stroke-linecap="round" stroke-linejoin="round"></path>
     </svg>`,
  ];
  return (
    <>
      <Header />

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="cnt-box">
              <h1 style={{ marginBottom: "40px" }}>How can we help?</h1>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Search our help center articles"
              />
              <Button variant="contained">Search</Button>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container id="cnt_bnr">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="cnt-bnr">
              <img src={logo} alt="Logo" className="cnt-image" />
              <div className="overlay"></div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container id="stk">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="one_frm">
              <h3>
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                Our 1form help centre has moved into help.realestate.com.au
              </h3>
              <p>
                We have rebuilt 1form from the ground up and agents can now
                access all of their rent needs within Rental Applications in
                Ignite. To support you with our move to Rental Applications in
                Ignite, you can still access any previous 1form information
                within our 1form section.
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container id="pop">
        <Grid container spacing={3}>
          {/* First input field */}
          <Grid item xs={4} className="hell">
            <Contactcard
              svgPath={svgPaths[0]}
              heading="Getting started"
              desc="A key starting point"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={4} className="hell">
            <Contactcard
              svgPath={svgPaths[1]}
              heading="Products"
              desc="Find the help you need for one of our products or features"
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={4} className="hell">
            <Contactcard
              svgPath={svgPaths[2]}
              heading="Listings and profiles"
              desc="Find the support you need for your online presence"
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container spacing={3}>
          {/* First input field */}
          <Grid item xs={4}>
            <Contactcard
              svgPath={svgPaths[3]}
              heading="Invoicing"
              desc="Seek out the answer for those tricky invoice or billing based questons"
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={4}>
            <Contactcard
              svgPath={svgPaths[3]}
              heading="Listing Errors"
              desc="Have a listing problem? View our troubleshooting guides here."
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={4}>
            <Contactcard
              svgPath={svgPaths[4]}
              heading="Policies"
              desc="All the important information relating to our policies and conditions"
            />
          </Grid>
        </Grid>
      </Container>

      <Container id="promt">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="">
              <h2 style={{ marginBottom: "75px" }}>Promoted articles</h2>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: "80px" }}>
          <Grid item xs={4}>
            <h4>Advertise with us</h4>
            <p>Thank you for your interest in advertising with...</p>
          </Grid>

          <Grid item xs={4}>
            <h4>How do I create a rental listing?</h4>
            <p>Visit our advertise your property for rent p...</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Property Manager Guide for Rental Applications in Ignite</h4>
            <p>Rental Applications in Ignite is the fastest a...</p>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: "80px" }}>
          <Grid item xs={4}>
            <h4>
              Applying for a rental property with realestate.com.au Rental
              Applications
            </h4>
            <p>realestate.com.au Rental Applications is the fa...</p>
          </Grid>

          <Grid item xs={4}>
            <h4>How to update a Property Profile page</h4>
            <p>Sign in to your My Real Estate account. If y...</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Transferring listings between agencies</h4>
            <p>This article explains the process of transferri...</p>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: "120px" }}>
          <Grid item xs={4}>
            <h4>Financial hardship</h4>
            <p>If you need to discuss your payment options wit...</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Payment options</h4>
            <p>You can pay your invoice in the following ways:...</p>
          </Grid>

          <Grid item xs={4}></Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default Contact;
