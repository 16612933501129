import React, { useContext } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Signup.css";
import { useState, useEffect } from "react";
import { multiStepContext } from "../StepContext";

const Questions = () => {
  const navigate = useNavigate();
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [error, setError] = useState("");

  const validateQuestions = async () => {
    // Count how many questions are answered
    const answeredQuestions = Object.values(userData).filter(
      (question) => question !== undefined && question !== ""
    ).length;

    //alert(answeredQuestions); // This alert should show the correct count of answered questions
    if (answeredQuestions >= 10) {
      setError("You cannot answer more than 3 Questions.");
    } else if (answeredQuestions === 6 || answeredQuestions === 7) {
      setError(
        "Please answer at least three questions to proceed.Or click on Skip button to create your own questions."
      );
    } else if (answeredQuestions === 9) {
      setError("");

      try {
        const response = await axios.post(
          "http://localhost:13000/signup",
          userData
        );
        if (response.status === 200) {
          toast.success(
            "Signup Successful.. A Verification Code to verify your Account has been sent to your registered email address.!!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );

          setTimeout(() => {
            setStep(4); // Proceed to the next step
          }, 3000);
        }
      } catch (err) {
        if (err.response) {
          const status = err.response.status;

          if (status === 409) {
            toast.error("Email is already used.. Please try again.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            setTimeout(() => {
              navigate("/signup");
            }, 5000);
          } else {
            // Log other errors to the console
            console.log(err);
          }
        }
      }
    }
  };

  return (
    <div id="signup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <ToastContainer />

        <Grid container spacing={2}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={10}>
            <div className="quespg">
              <h3>Security Questions</h3>
              <p>Answer Atleast Three Questions among these 10.</p>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <div>
                    <TextField
                      id="question_box"
                      label="What is your Favourite Food?"
                      variant="outlined"
                      margin="normal"
                      name="question1"
                      fullWidth
                      value="What is your Favourite Food?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question1: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Drink?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question2"
                      value="What is your Favourite Drink?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question2: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Colour?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question3"
                      value="What is your Favourite Colour?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question3: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Sport?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question4"
                      value="What is your Favourite Sport?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question4: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="Who is your Favourite Super Hero?"
                      variant="outlined"
                      margin="normal"
                      name="question5"
                      fullWidth
                      value="Who is your Favourite Super Hero?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question5: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Year would you like to be Born?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question6"
                      value="What Year would you like to be Born?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question6: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Country of the World would you like to Live?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question7"
                      value="What Country of the World would you like to Live?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question7: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Planet in the Cosmos would you like to Visit?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question8"
                      value="What Planet in the Cosmos would you like to Visit?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question8: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Super Powers would you like to Have?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question9"
                      value="What Super Powers would you like to Have?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question9: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="If you had 1 Wish, what Wish would that Be?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="question10"
                      value="If you had 1 Wish, what Wish would that Be?"
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          question10: e.target.value,
                        }))
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div>
                    <TextField
                      id="question_box"
                      label="What is your Favourite Food?"
                      variant="outlined"
                      margin="normal"
                      name="answer1"
                      fullWidth
                      value={userData.answer1}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer1: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Drink?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer2"
                      value={userData.answer2}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer2: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Colour?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer3"
                      value={userData.answer3}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer3: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="What is your Favourite Sport?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer4"
                      value={userData.answer4}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer4: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="Who is your Favourite Super Hero?"
                      variant="outlined"
                      margin="normal"
                      name="answer5"
                      fullWidth
                      value={userData.answer5}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer5: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Year would you like to be Born?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer6"
                      value={userData.answer6}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer6: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Country of the World would you like to Live?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer7"
                      value={userData.answer7}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer7: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Planet in the Cosmos would you like to Visit?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer8"
                      value={userData.answer8}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer8: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id=""
                      label="What Super Powers would you like to Have?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer9"
                      value={userData.answer9}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer9: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div>
                    <TextField
                      id=""
                      label="If you had 1 Wish, what Wish would that Be?"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="answer10"
                      value={userData.answer10}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          answer10: e.target.value,
                        }))
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              <div className="back_btn">
                <Button
                  id="sbbtn1"
                  variant="contained"
                  onClick={() => setStep(1)}
                >
                  Back
                </Button>

                <Button
                  id="sbbtn1"
                  variant="contained"
                  onClick={validateQuestions}
                >
                  Submit
                </Button>

                <Button variant="outlined" onClick={() => setStep(3)}>
                  Skip
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={1}></Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Questions;
