import React from "react";
import "../styles/Newhome.css";

const Insight = ({ imageUrl, heading, desc }) => {
  return (
    <>
      <div class="sale_nw">
        <div class="box">
          <div class="img-box10">
            <img src={imageUrl} alt="" />
          </div>
          <div class="detail-box10" style={{ textAlign: "left" }}>
            <h6>{heading}</h6>
            <p>{desc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insight;
