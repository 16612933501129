// StepContext.js
import React, { createContext, useState } from "react";

export const multiStepContext = React.createContext();

export const StepProvider = ({ children }) => {
  const [currentStep, setStep] = useState(1);
  const [userData, setUserData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  function submitData() {
    setFinalData((prevFinalData) => [...prevFinalData, userData]);
    setUserData([]);
    setStep(1);
  }

  return (
    <multiStepContext.Provider
      value={{
        currentStep,
        setStep,
        userData,
        setUserData,
        finalData,
        setFinalData,
        submitData,
      }}
    >
      {children}
    </multiStepContext.Provider>
  );
};
