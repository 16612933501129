import React from "react";
import { Container, Grid } from "@mui/material";
import "../styles/Homelisting.css";
import Homelistcard from "./Homelistcard";
import logo1 from "../images/lcl.jpg";
import logo2 from "../images/cnt.jpg";
import logo3 from "../images/cnt1.jpg";
import logo4 from "../images/cnt2.jpg";
import logo5 from "../images/cnt3.jpg";
import logo6 from "../images/drm9.jpg";
import logo7 from "../images/drm8.jpg";
import logo8 from "../images/drm7.jpg";
import logo9 from "../images/drm6.jpg";
import logo10 from "../images/drm5.jpg";
import logo11 from "../images/drm4.jpg";
import logo12 from "../images/drm3.jpg";
import logo13 from "../images/drm2.jpg";
import logo14 from "../images/drm.jpg";
import image1 from "../images/bed.png";
import image2 from "../images/bath.png";
import image3 from "../images/car.png";

const Homelisting = () => {
  return (
    <div>
      <Container className="homelist">
        <h2>International Display Homes Listings</h2>
      </Container>
      <Container>
        <Grid container spacing={3}>
          {/* First input field */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo1}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo2}
              heading="CONTACT AGENT"
              description="16 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo3}
              heading="$2,700,000 TO $2,900,000"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo4}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo5}
              heading="CONTACT AGENT"
              description="22 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo1}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo3}
              heading="CONTACT AGENT"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo4}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo6}
              heading="CONTACT AGENT"
              description="14 Charming Street"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo7}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo8}
              heading="CONTACT AGENT"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo9}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo10}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo11}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo12}
              heading="CONTACT AGENT"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo13}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo14}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo14}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo7}
              heading="CONTACT AGENT"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo4}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo1}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo2}
              heading="CONTACT AGENT"
              description="16 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo3}
              heading="$2,700,000 TO $2,900,000"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo4}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo1}
              heading="CONTACT AGENT"
              description="14 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
          {/* Button 1 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo2}
              heading="CONTACT AGENT"
              description="16 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          {/* Button 2 */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo3}
              heading="$2,700,000 TO $2,900,000"
              description="18 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Homelistcard
              imageUrl={logo4}
              heading="CONTACT AGENT"
              description="20 Charming Street, Hampton, VIC 3188"
              image1={image1}
              image2={image2}
              image3={image3}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Homelisting;
