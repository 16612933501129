import React from "react";
const Explore = ({ imageUrl, heading }) => {
  return (
    <>
      <div class="sale_container">
        <div class="box" id="explore">
          <div class="img-box1">
            <img src={imageUrl} alt="" style={{ width: "100%" }} />
          </div>
          <div class="detail-box1" style={{ marginTop: "10px" }}>
            <h6
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "18px",
                marginTop: "0px",
                textAlign: "left",
              }}
            >
              {heading}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
